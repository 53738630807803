* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.home{
  min-height: 87vh;

}

.topper {
  width: 100%;
  height: 44px;
  /* background-color: hsl(239, 69%, 27%); */
  background-color: hsl(201, 96%, 30%);
}

.menu {
  width: 100%;
  height: 80px;
  position: sticky;
  background-color: #fff;
}

.onscroll {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0px 2px 55px rgba(168, 182, 218, 0.32);
}

.menu,
.topper {
  display: flex;
  align-items: center;
  padding: 0 10%;
  font-weight: 500;
}

.menu, .topper {
  justify-content: space-between;
}

body {
  /* font-family: Outfit, sans-serif; */
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
}

li {
  list-style-type: none;
  display: inline;
  padding: 10px;
}

li>a {
  text-decoration: none;
  color: #000;
}

section {
  width: 100%;
  height: 100%;
}

.shape,
.shape1 {
  position: absolute;
  z-index: -1;
  background-repeat: no-repeat;
}

.shape1 {
  left: 0;
}

.shape {
  right: 0;
}

.home,
.row {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}


.row {
  height: 100%;
  width: 100%;
}

h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size:5vw;
  /* color: hsl(239, 69%, 27%); */
  line-height: 1.2;
  color:hsl(201, 96%, 30%);
}

.heading {
  width: 40%;
}

.cookies{
  position: sticky;
  bottom: 0;
background-color: hsl(239, 69%, 27%);
color: white;
padding: 20px;
text-align: center;
font-size: 18px;
z-index: 999;
}

.cookies>button{
  height: 35px;
  width: 100px;
padding: 0;
margin-left: 10px;
}

@media (max-width: 700px) {
.heading {
  width: 80%;
  }
  .block{
    height: auto;
  }
  h3{
    font-size: 30px;
  }

}


.heading>p{
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
}

.heading>a>button{
  width: auto;
  margin-top: 20px;
}
.hero>img {
width: 100%;
max-height: 450px;
  animation: mymove 5s infinite;
  transform: translate(0px, 50px);
  border-radius: 50%;
  border: 1px dashed black;
 padding: 15px;

}

@keyframes mymove {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(-50px); }
  100% { transform: translateY(0); }
}


.projects, .offers{
  /* background-color: hsl(205, 100%, 96%); */
  padding: 10%;
  background-color: hsl(0, 0%, 95%);
}


.contacts{
  background-color: white;
  padding: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
align-content: center;

}

.team, .offers{
  /* background-color: hsl(223, 92%, 50%); */
  border-radius: 20px;
  padding: 10% 4%;
  width: 80%;
  z-index : 4;
  background-color: hsl(201, 96%, 30%);
}
.offers{
  margin:auto;
  position: relative;
}

.team{
  margin:-90px auto 0px auto;
}
.projects{
  margin-top: -120px;
  /* position: relative; */
  z-index: -3;
}

.team>h3, .offers>h3, .contacts>h3, .projects > h3{
  font-size: 50px;
  font-weight: 500;
}

.team>h3, .offers>h3{
  color: white;

}

.contacts>h3, .projects>h3{
  /* color: hsl(239, 69%, 27%); */
  margin-left: 60px;
  color: hsl(200, 35%, 32%)
}

h3{
animation: fadein 1s linear;
opacity: 1;

}

.footer a{
color: white;
}
.input-email{
 border: 1px dashed black;
  width: 300px;
  height: 50px;
}
.sib-form{
  display:flex;
  flex-direction: column;
  padding: 10px 0 0 0 ;

}
.alert{
  width: 100%;
  background: rgba(228, 248, 240, 0.7);
  border: solid #1ea97c;
  border-width: 0px;
  color: #1ea97c;
  height: 60px;
  text-align: center;
  border-radius: 20px;
  padding:10px;
  font-weight: 600;
}
@keyframes fadein {
	0% {
	  opacity: 0;
	  -webkit-transform: translateY(20px);
	  -ms-transform: translateY(20px);
	  transform: translateY(20px);
	}
  
	100% {
	  opacity: 1;
	  -webkit-transform: translateY(0);
	  -ms-transform: translateY(0);
	  transform: translateY(0);
	}
}

.column>img {
  width: 200px;
  object-fit: contain;
  height:200px;
  border-radius: 50%;
}

.footer>.column> img{
  height: auto;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column>p {
  font-weight: 500;
  font-size: 16px;
  color: white;
  margin: 5px;
  text-align: center;
}

h2 {
  font-size: 18px;
  color: white;
  background-size: 0% 1px, 0 1px;
  transition: background-size 0.3s linear;
  background-image: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
  background-repeat: no-repeat;
  background-position: 100% 100%, 0 100%;
  display: inline;
  margin-top: 10px;
}

h2:hover {
  background-size: 0% 1px, 100% 1px;
}

.row>a, .topper > span > a {
  color: white;
  margin-right: 10px;
}

.column>.row {
  margin-top: 10px;
  justify-content: center;
}


.block{
  width: 300px;
  height:250px;
  /* background-color: hsl(223, 100%, 55%); */
  border-radius: 10px;
  padding:30px;
  margin: 10px;
  background-color: hsl(202, 79%, 36%);
}



h4{
  font-weight: 500;
  font-size: 24px;
  color: white;
  margin-bottom:20px;
}

.block>p{
  font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: white;
}
input, textarea{
  width: 100%;
  font-size: 15px;
  color: black;
  border: 1px solid transparent;
  /* background-color: hsl(205, 100%, 96%); */
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 0px 20px;
  background-color: hsl(0, 0%, 95%);
}

input{
  height: 60px;
}

textarea{
  height: 150px;
  padding-top: 10px;
}

.contacts> h3{
  margin-bottom: 20px;
}

button{
  height: 50px;
  width: 150px;
  padding: 0 15px;
  font-size: 12px;
  display: inline-block;
  /* background-color: hsl(210, 100%, 49%); */
  color: white;
  text-align: center;
  padding: 0 35px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  border-radius: 5px;
  z-index: 1;
  overflow: hidden;
  border: none;
  cursor: pointer;
  background-color: hsl(201, 96%, 30%);
}

button:hover{
  background-color: #000;
}

button p{
  z-index: 2;
}

footer{
  /* background-color: hsl(239, 69%, 27%); */
  height:30vh;
  background-color: hsl(201, 96%, 30%);

}

.topper>p{
  color: white;
}

.technologies{
  width: 100%;
  min-height: 80px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.technologies > span{
  display: flex;
  flex-direction: row;
 align-items: center;
 font-weight: 500;
}

.proj1{
  width: 500px;
  height: auto;
  background: white;
  border-radius: 20px;
padding: 20px;
margin: 20px;
overflow: hidden;
}

.proj1>a> h4{
  color: hsl(239, 69%, 27%);
}
.proj1>a{
  text-decoration: none;
}
.proj1> img{
  border-radius: 10px;
object-fit: scale-down;
}

footer{
  display:flex;
  flex-direction: column;
justify-content: center;
  
}

nav{
  display: none;
}

.mobileMenu{
  display: none;
}

.header{
  width: 100%;
    padding-top: 200px;
    padding-bottom: 190px;
  background-size: cover;
  background-position: center;
background-image: url("./components/blog/breadcrumb.png");
display: flex;
justify-content: center;
align-items: center;
}

.header>h1{
  text-align: center;

}

.cloud{
  position: absolute;
 top: 60%;
  left: 50%;
  transform: translate(-50%);
}
.cloud>a{
  height: 70px;
  width: 70px;
  text-align: center;
  line-height: 70px;
  font-size: 20px;
  border-radius: 50%;
  background-color: white;
  color: hsl(210, 100%, 49%);
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.cloud>a::before{
  height: 25px;
  width: 25px;
  background-color:white;
  border-radius: 50%;
  content: "";
  position: absolute;
  left: -15px;
  top: 20px;
}

.cloud>a::after{
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  content: "";
  position: absolute;
  right: -15px;
  top: 20px;
}

.content{
width: 100%;
height: auto;
display: flex;
flex-direction: row;
padding: 5%;
justify-content: center;
}
.posts{
  width: 55%;
}
.recents{
  width: 30%;
  border: 1px solid  hsl(0, 0%, 96%);
  height: 500px;
overflow: hidden;
  font-size: 14px;
  padding: 20px;
}
.recents>span>h3{
  margin-left: 10px;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 500;
  color: black;
  line-height: 22px;
}
.recents>h3{
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 30px;
  color: hsl(239, 69%, 27%);
  margin-left: 5px;
}
.recents>span>img{
  border-radius: 10px;
}
.post>span>p{
  font-size: 16px;
  font-weight: 500;
  color: #8A90A2;
margin-left: 5px;
  text-transform: uppercase;
}

.post{
  margin-top: 28px;
  width: 90%;
  text-align: justify;
}

.post>h1{
  font-size: 40px;
  text-align: left;
}
.post>img{
  border-radius: 20px;
  width: 100%;
  object-fit: none;
}
.post>span, .recents>span{
  display: flex;
  flex-direction: row;
  padding: 5px;
align-items: center;
}
.post>p{
  line-height: 30px;
  margin-bottom: 28px;
  font-size: 17px;
  
}
.link{
text-decoration: none;
color: white;
}

.recents>span> .link{
  color: black;
  margin-left: 5px;
  font-weight: 100;
}

.recents>span>.link>h3{
  font-weight: 500;
}

.modal{
padding: 40px;
border-radius: 20px;
background-color: hsl(210, 100%, 49%);

color: white;
top: 100px;
position: fixed;
text-align: center;
left: 30%;
right: 30%;
z-index: 999;
}

.cookies>button{
  cursor: pointer
}

#privacy > ul li{
  display: block;
}

.ec-cart-widget{
  width: 5%;
  position: absolute;
  right:0;
}

html#ecwid_html body#ecwid_body .horizontal-menu-container.horizontal-desktop nav.horizontal-menu{
  display: none;
}


@keyframes rock {
  0%{left: 550px;}
  100%{left: -50px;}
}

.jump-animation {
  animation: jump 0.5s;
}

@keyframes jump {
  0%{top: 225px;}
  50%{top: 75px;}
  75%{top: 75px;}
  100%{top: 225px;}
}

@media screen and (max-width: 1000px){
 .ec-cart-widget{
    display: none;
    }
}

@media screen and (max-width: 710px){
.menu >ul{
display: none;
}
/* lai mobile menu neraada hero bildi  */
.menu{
position: sticky;
}
.row-menu{
  height:auto;
  justify-content: space-between;
  display:flex;
  flex-direction: row;
  
}
nav{
  display: block;
  padding: 5px 5px 0 5px;
  background-color: hsl(210, 100%, 49%);
  border-radius: 10px;
}

.mobileMenu{
  display: block;
  width: 400px;
  padding: 40px;
  background-color: #000;
  right: 0;
  top: 0;
  height: 100%;
  opacity: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  z-index: 9999;

  }

  .mobileMenu > ul> li> a{
    width: 100%;
    padding: 15px 0;
    color: #fff;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 500;
  }
  .mobileMenu> ul> li{
    border-bottom: 1px solid grey;
    width: 100%;
  }
  .mobileMenu>ul{
    display: flex;
    flex-direction: column;
  }
  .mobileMenu > ul> li> a:hover{
    color: hsl(210, 100%, 49%);
  }
  .hero{
    display: none;
  }
.proj1{
  width:90%;
  height: auto;
}
.proj1>img{
  height: 40%;
}
.technologies{
  height: auto;
  flex-wrap: wrap;
  width: 80%;
}
.contacts>h3, .projects>h3, .offers>h3{
margin-left: 0;
font-size: 40px !important;

}
.contacts>h3, .projects>h3{
  margin-top: 120px;
}
h1{
  font-size: 40px;

}
button::after{
  display: none;
}
button{
  transition: none;
}
button:hover{
  background-color: hsl(210, 100%, 49%);
}

.content{
  flex-direction: column;
}
.posts{
  width: 100%;

}
.recents{
  width: 100%;
  margin-top: 28px;
}

.recents>span>a>h3{
  font-size: 16px;

}
}

